const acceptTermsSignature = {
  rgpdCheckboxTrueLabel:
    "AUTORIZO el uso de mis datos mencionados en esta página para la finalidad mencionada en el punto de protección de datos.",
  rgpdCheckboxFalseLabel: "NO autorizo el uso de mis datos.",
  rgpdPrivacyPolicy:
    'De conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR)  y la Ley Orgánica (ES) 15/1999 de 13 de diciembre (LOPD), le informamos que los datos personales recabados del propio interesado serán tratados bajo la responsabilidad de Cordis Integralis Service, SL.  con la finalidad de TRAMITAR EL REGISTRO OFICIAL EN USO DEL DEA, para el envío de comunicaciones sobre artículos de nuestra web a través de plataforma de mailing mailrelay y para hacer uso de la imagen en material didáctico y divulgativo (folletos, web, presentaciones, etc...) para la promoción y divulgación de las actividades que le son própias y en los medios de difusión oportunos en cada caso (impreso, electrónico o audiovisual). Se conservarán mientras exista un interés mutuo para ello. Los datos no serán comunicados a terceros, salvo obligación judicial. Le informamos que puede ejercer los derechos de acceso, rectificación, portabilidad y supresión de sus datos y los de limitación y oposición a su tratamiento enviando un mensaje al correo electrónico a privacidad@cordisintegralis.es , en ambos casos, con la referencia "Protección de Datos" y adjuntando una copia del DNI.' +
    "<p>EN CASO DE NO ACEPTAR NO EMITIREMOS NINGUNA DOCUMENTACIÓN SOBRE LA PRESENTE FORMACIÓN</p>",
  semicyucCheckboxTrueLabel:
    "AUTORIZO el uso de mis datos mencionados en esta página para la finalidad mencionada en el punto de protección de datos",
  semicyucCheckboxFalseLabel: "NO autorizo el uso de mis datos.",
  semicyucPrivacyPolicy:
    "<p>De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 relativo a la protección de los datos personales de las personas físicas (RGPD), le informamos que la información personal que ha facilitado quedará debidamente registrada e incorporada a los sistemas de tratamiento de datos responsabilidad de la SEMICYUC y ello, con la finalidad de atender y gestionar su solicitud de inscripción al Curso. Por tal motivo, si usted no está conforme con el tratamiento descrito no podremos procesar el objeto de su solicitud. Igualmente, venimos a informarle de que su información personal no será comunicada a ningún destinatario salvo a aquellos que nos autorice usted o exija la ley, así como tampoco será destinada a ninguna otra finalidad distinta a la aquí informada. Usted podrá ejercer los derechos de acceso, rectificación, supresión, limitación de algún tratamiento específico, portabilidad, decisiones automatizadas y oposición al tratamiento descrito en la siguiente dirección secretaria@semicyuc.org. No obstante, si así lo desea, puede ponerse en contacto con el Delegado de Protección de Datos a través de la siguiente dirección:rgpd@semicyuc.org. Para el caso de que Ud. precise conocer información adicional sobre el tratamiento de sus datos personales, puede consultar la información adicional en nuestra página web a través del siguiente hipervínculo: http://www.semicyuc.org/</p>" +
    '<p>En caso de que Ud. no quisiera que tratásemos sus datos para remitirle información sobre actividades formativas futuras que desarrolle el PPNRCP de la SEMICYUC, por favor marque la siguiente casilla o remita un correo electrónico a la dirección secretaria@semicyuc.org, con el texto “BAJA COMUNICACIONES COMERCIALES" del PNRCP de la SEMICYUC.</p>',
};

export { acceptTermsSignature };
