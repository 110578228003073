<template>
  <v-form v-model="formIsValid" ref="studentDataForm" @submit.prevent="acceptTerms">
    <v-row dense>
      <v-col cols="12" sm="4">
        <v-select
          :items="genders"
          item-value="value"
          label="Género"
          v-model="formData.user.gender"
          :rules="[$data.$globalRules.required]"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          label="Nombre"
          v-model="formData.user.name"
          :rules="[$data.$globalRules.required]"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          label="Apellidos"
          v-model="formData.user.surname"
          :rules="[$data.$globalRules.required]"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          label="Email"
          v-model="formData.user.email"
          :rules="[$data.$globalRules.email]"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-prd-date-picker-field
          v-model="formData.user.birthDate"
          :rules="[$data.$globalRules.required]"
          label="Fecha de nacimiento"
          is-birth-date
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card max-height="200px" outlined style="overflow-y: scroll">
          <v-card-title>Privacidad de datos</v-card-title>
          <v-card-text v-html="rgpdPrivacyPolicy"></v-card-text>
        </v-card>
        <v-radio-group v-model="formData.rgpdAuthorize" :rules="[$data.$globalRules.required]">
          <v-radio :value="true" :label="rgpdCheckboxTrueLabel"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" v-if="semicyuc">
        <v-card max-height="200px" outlined style="overflow-y: scroll">
          <v-card-title>Privacidad de datos SEMICYUC</v-card-title>
          <v-card-text v-html="semicyucPrivacyPolicy"></v-card-text>
        </v-card>
        <v-radio-group v-model="formData.semicyucAuthorize" :rules="[$data.$globalRules.required]">
          <v-radio :value="true" :label="semicyucCheckboxTrueLabel"></v-radio>
          <v-radio :value="false" :label="semicyucCheckboxFalseLabel"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <p class="subtitle-2">Firmar asistencia</p>
        <signature-pad v-model="formData.signature"></signature-pad>
      </v-col>

      <v-col cols="12" class="mt-6 text-center">
        <v-btn
          color="primary"
          type="submit"
          large
          :loading="sendingTermsForm"
          :disabled="!formData.signature"
        >
          <v-icon mr-2>mdi-check</v-icon>
          Validar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { acceptTermsSignature } from "@/utils/largeTexts";
import genders from "@/utils/helpers";

export default {
  components: {
    signaturePad: () => import("@/components/signature-pad"),
    vPrdDatePickerField: () => import("@/components/v-prd/DatePickerTextField"),
  },
  props: {
    semicyuc: { type: Boolean, default: false },
    formData: { type: Object, require: true },
    studentId: { type: Number, default: 0 },
    assistanceId: { type: Number, default: 0 },
  },
  data: () => ({
    genders: null,
    formIsValid: null,
    sendingTermsForm: null,
    ...acceptTermsSignature,
  }),
  beforeMount() {
    this.genders = genders;
    this.formData.rgpdAuthorize = true;
  },
  methods: {
    async acceptTerms() {
      this.$refs.studentDataForm.validate();

      if (this.formIsValid && !!this.formData.signature) {
        const {
          rgpdAuthorize,
          user: { email },
        } = this.formData;

        if (!rgpdAuthorize) {
          const missingRgpdConfirmed = await this.confirmMissingRgpd();
          if (!missingRgpdConfirmed) return;
        }

        if (!email) {
          const missingEmailConfirmed = await this.confirmMissingEmail();
          if (!missingEmailConfirmed) return;
        }

        try {
          this.sendingTermsForm = true;
          if (!this.semicyuc) delete this.formData.fundaeCheckbox;
          const userPayload = { ...this.formData.user };
          const termsPayload = { ...this.formData };
          delete termsPayload.user;

          await this.$store.state.currentService.updateUserData(this.studentId, userPayload);
          await this.$store.state.currentService.submitAcceptTerms(this.assistanceId, termsPayload);

          this.termsFormCompleted = true;
          this.$emit("formCompleted");
        } catch (e) {
          this.$notifyError(undefined, "Error al enviar los datos | " + e);
        } finally {
          this.sendingTermsForm = false;
        }
      }
    },
    async confirmMissingRgpd() {
      const message =
        "<p>¿<strong>Confirma que NO AUTORIZA</strong> el uso de sus datos personales?</p>" +
        "<p>Esta decisión nos impide registrar su formación y no podremos emitir ningún certificado de su participación en la formación.</p>";
      return await this.$confirm(message, {
        title: "¡No podremos registrar su formación!",
        width: 450,
      });
    },
    async confirmMissingEmail() {
      const message =
        "<p><strong>¿Confirma que no quiere facilitarnos un correo?</strong></p>" +
        "<p>Si no nos facilita su correo eletrónico no podremos enviarle su certificado.</p>";
      return await this.$confirm(message, {
        title: "¡No podremos enviarle su certificado!",
        width: 450,
      });
    },
  },
};
</script>

<style scoped></style>
